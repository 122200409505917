import React from "react"
import { Admin, Resource, AuthProvider } from "react-admin"

import { Dashboard } from "./dashboard"
import locations from "./locations"
import rooms from "./rooms"
import apps from "./apps"
import projectionCalibrations from "./projection-calibrations"
import devices from "./devices"
import users from "./users"
import tokens from "./tokens"
import controls from "./controls"

import { useAmplifyDataProvider, useAuthProvider } from "ra-aws-amplify"

import config from "./aws-exports"
import schema from "./graphql/schema.json"
import * as queries from "./graphql/queries"
import * as mutations from "./graphql/mutations"

import { CssBaseline } from "@material-ui/core"
import { light } from "./theme"

function App() {
  const dataProvider = useAmplifyDataProvider({
    config,
    schema,
    queries,
    mutations,
  })
  const authProvider = useAuthProvider()

  return dataProvider ? (
    <Admin
      theme={light}
      authProvider={(authProvider as any) as AuthProvider}
      dataProvider={
        dataProvider
          ? dataProvider
          : { getList: () => Promise.resolve({ data: [], total: 0 }) }
      }
      title="CityDNA Platform Management"
      dashboard={Dashboard}
    >
      <>
        <CssBaseline />
      </>

      <Resource name="Location" {...locations} />
      <Resource name="Room" {...rooms} />
      <Resource name="App" {...apps} />
      <Resource
        options={{ label: "Projection Calibrations" }}
        name="ProjectionCalibration"
        {...projectionCalibrations}
      />
      <Resource name="Device" {...devices} />
      <Resource name="User" {...users} />
      <Resource name="Token" {...tokens} />
      <Resource name="Control" {...controls} />
    </Admin>
  ) : (
    <>Loading</>
  )
}

export default App
