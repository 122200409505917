import React, { FC } from "react"
import { List, Datagrid, TextField, EditButton } from "react-admin"
import { AmplifyPagination } from "ra-aws-amplify"

export const ControlList: FC = (props) => {
  return (
    <List {...props} pagination={<AmplifyPagination />}>
      <Datagrid>
        <TextField source="appId" label="App ID" />
        <TextField source="roomCapacity" />
        <TextField source="emitLimit" />
        <TextField
          source="emitLimitResetTime"
          label="Emit limit reset time (seconds)"
        />
        <TextField source="entryTimeLimit" label="Entry time limit (minutes)" />
        <EditButton />
      </Datagrid>
    </List>
  )
}
