import React from "react"
import {
  List,
  Datagrid,
  TextField,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  EditButton,
} from "react-admin"
import { LatLngField } from "../common/LatLngField"
import { AmplifyPagination } from "ra-aws-amplify"

export const LocationList: React.FC = props => {
  return (
    <List {...props} pagination={<AmplifyPagination />}>
      <Datagrid>
        <TextField source="title" />
        <LatLngField source="viewport" />
        <ReferenceManyField
          label="Rooms"
          reference="Room"
          target="roomsByLocation"
        >
          <SingleFieldList>
            <ChipField source="title" />
          </SingleFieldList>
        </ReferenceManyField>
        <EditButton />
      </Datagrid>
    </List>
  )
}
