import React, { FC, BaseSyntheticEvent, useState } from "react"
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  CheckboxGroupInput,
  TextInput,
  useReference,
} from "react-admin"
import { MapInput } from "../common"
import { makeStyles, Theme } from "@material-ui/core"
import slugify from "slugify"
import { DISPLAY_CHOICES } from "../common/defaultChoices"

export const useStyles = makeStyles<Theme>((theme) => ({
  checkbox: {
    "& .MuiFormGroup-root": {
      marginTop: theme.spacing(1),
    },
  },
}))

export const RoomCreate: FC = (props) => {
  const classes = useStyles()
  const [locationId, setLocationId] = useState<any>({
    id: undefined,
  })

  const handleSelectedLocation = (e: BaseSyntheticEvent) => {
    setLocationId({ id: e.target?.value })
  }

  const { referenceRecord } = useReference({
    reference: "Location",
    id: locationId?.id,
  })

  /** call prior to submitting the form */
  const transform = (data: any) => ({
    ...data,
    slug: slugify(data.title, { lower: true }),
  })

  return (
    <Create
      {...props}
      // @ts-ignore
      redirect="list"
      transform={transform}
    >
      <SimpleForm>
        <ReferenceInput
          label="Location"
          source="locationId"
          reference="Location"
          onChange={handleSelectedLocation}
        >
          <SelectInput optionText="title" source="locationId" />
        </ReferenceInput>
        <TextInput label="Title" source="title" />
        <MapInput
          source="latlng"
          point={true}
          existingLocation={referenceRecord}
          type="room"
        />
        <CheckboxGroupInput
          source="displays"
          label="Displays in this room"
          formClassName={classes.checkbox}
          format={(data: any) =>
            data && data.map((display: any) => display.type)
          }
          parse={(data: any) => data.map((display: any) => ({ type: display }))}
          optionText="type"
          optionValue="type"
          choices={DISPLAY_CHOICES}
          row={false}
        />
      </SimpleForm>
    </Create>
  )
}
