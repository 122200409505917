import React, { FC } from "react"
import { List, Datagrid, TextField, DateField, EditButton } from "react-admin"
import { AmplifyPagination } from "ra-aws-amplify"
import { ReferenceField } from "react-admin"

export const DeviceList: FC = (props) => {
  return (
    <List {...props} pagination={<AmplifyPagination />}>
      <Datagrid>
        <TextField source="name" />
        <TextField source="currentAppScreen" />
        <DateField source="createdAt" />
        <ReferenceField label="Room" reference="Room" source="roomId">
          <TextField source="title" />
        </ReferenceField>
        <TextField source="display.type" label="Display type" />
        <EditButton />
      </Datagrid>
    </List>
  )
}
