import { RoomEdit } from "./RoomEdit"
import { RoomList } from "./RoomList"
import { Room as RoomIcon } from "@material-ui/icons"
import { RoomCreate } from "./RoomCreate"

const exports = {
  list: RoomList,
  create: RoomCreate,
  edit: RoomEdit,
  icon: RoomIcon,
}

export default exports
