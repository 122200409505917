import React from "react"
import {
  Create,
  SimpleForm,
  TextInput,
  regex,
  required,
  CheckboxGroupInput,
} from "react-admin"
import { S3ImageInput } from "ra-aws-amplify"
import { DropPlaceholder } from "../common"
import { DISPLAY_CHOICES } from "../common/defaultChoices"

import { makeStyles, Theme } from "@material-ui/core"
const useStyles = makeStyles<Theme>((theme) => ({
  codeInput: {
    "& input": {
      fontFamily: "'JetBrains Mono', 'Courier New', sans-serif",
    },
  },
  checkbox: {
    "& .MuiFormGroup-root": {
      marginTop: theme.spacing(1),
    },
  },
}))

export const AppCreate: React.FC = (props) => {
  const classes = useStyles()
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="title" validate={required()} />
        <TextInput
          source="npmPackageName"
          label="NPM package url"
          className={classes.codeInput}
        />
        <TextInput
          source="appId"
          label="App ID (if exists)"
          helperText="The id to identify different entry points in a single app (e.g. aboriginal-melbourne and state-library-images)."
          validate={regex(
            /[a-z]+(-[a-z]+)*/i,
            "App ID must consist of only lowercase letters and hyphens (-)"
          )}
        />
        <TextInput source="description" />
        <CheckboxGroupInput
          source="supportedDisplays"
          label="Compatible displays"
          formClassName={classes.checkbox}
          format={(data: any) =>
            data && data.map((display: any) => display.type)
          }
          parse={(data: any) => data.map((display: any) => ({ type: display }))}
          optionText="type"
          optionValue="type"
          choices={DISPLAY_CHOICES}
          row={false}
        />
        <S3ImageInput
          source="coverImage"
          level="public"
          dropzoneOptions={{}}
          // @ts-ignore
          placeholder={
            <DropPlaceholder>
              Drop app icon here <br /> (or click to select)
            </DropPlaceholder>
          }
        />
      </SimpleForm>
    </Create>
  )
}
