import React from "react"
import { Box, Typography } from "@material-ui/core"
import { Title } from "react-admin"
import config from "../aws-exports"

export const Dashboard: React.FC = () => (
  <>
    <Title
      title={`CityDNA Admin - ${
        config.aws_cloud_logic_custom[0].endpoint.split("/").slice(-1)[0].toUpperCase() ||
        "Can't detect environment"
      }`}
    />
    <Box
      pt={2}
      pb={4}
      display="flex"
      flexGrow={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h2">CityDNA Platform Management System</Typography>
      <Typography variant="body1" align="center">
        Welcome to HQ. Keep track of the device fleet and usage, manage
        locations, rooms, apps and users all in one place.
      </Typography>
    </Box>
  </>
)
