import React from "react";
import { Box, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        color: theme.palette.grey[100],
    },
    title: {
        fontWeight: 500,
        fontSize: 12,
    },
}));
export const Legend = ({ title, body: Body, items, shape, }) => {
    const classes = useStyles();
    return (React.createElement(Box, { className: classes.root },
        title && React.createElement(Box, { className: classes.title }, title),
        React.createElement(Box, { pl: title ? 1 : 0, pt: title ? 1 : 0 },
            React.createElement(Body, { items: items, shape: shape }))));
};
