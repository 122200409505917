import { Theme } from "@material-ui/core"
import { createTheme } from "@material-ui/core/styles"
import { base } from "@citydna/common"
import merge from "lodash.merge"

export const theme = createTheme({
  ...base,
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiFilledInput: {
      root: {
        borderRadius: 4,
        fontWeight: "normal",
        "& :-webkit-autofill": {
          backgroundColor: "transparent",
          transition: "background-color 5000s ease-in-out 0s",
        },
      },
      underline: {
        "&:hover:before": {
          borderBottomColor: "transparent",
        },
        "&:before": {
          left: 4,
          right: 4,
          borderBottomColor: "transparent",
        },
      },
    },
  },
})

const lightTheme: Partial<Theme> = {
  overrides: {
    MuiChip: {
      root: {
        // @ts-ignore
        backgroundColor: base?.palette.grey[200],
        // @ts-ignore
        color: base.palette.grey[500],
      },
    },
  },
}

export const light = merge(theme, lightTheme)
