import React from "react"
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  CheckboxGroupInput,
  useReference,
  useQuery,
} from "react-admin"
import { MapInput } from "../common"
import { useStyles } from "./RoomCreate"
import {DISPLAY_CHOICES} from "../common/defaultChoices"

export const RoomEdit: React.FC<any> = (props) => {
  const classes = useStyles()
  const { data } = useQuery({
    type: "getOne",
    resource: "Room",
    payload: { id: props.id },
  })
  const { referenceRecord } = useReference({
    reference: "Location",
    id: data?.locationId,
  })

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="title" />
        <ReferenceInput reference="Location" source="locationId">
          <SelectInput optionText="title" optionValue="id" />
        </ReferenceInput>
        <MapInput
          point={true}
          source="latlng"
          existingLocation={referenceRecord}
          currentRoomId={props.id}
          type="room"
        />
        <CheckboxGroupInput
          source="displays"
          label="Displays in this room"
          formClassName={classes.checkbox}
          format={(data: any) =>
            data && data.map((display: any) => display.type)
          }
          parse={(data: any) => data.map((display: any) => ({ type: display }))}
          optionText="type"
          optionValue="type"
          choices={DISPLAY_CHOICES}
          row={false}
        />
        <ReferenceInput
          reference="App"
          source="roomNowPlayingId"
          label="App now playing"
        >
          <SelectInput optionText="title" optionValue="id" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  )
}
