import React, { forwardRef } from "react";
import { Box, AppBar, Toolbar, makeStyles, useMediaQuery, } from "@material-ui/core";
import MicroLogo from "../MicroLogo";
/**
 * Change to use [theme.breakpoints]
 */
const useStyles = makeStyles(theme => ({
    appbar: {
        [theme.breakpoints.only("xs")]: {
            backgroundColor: theme.palette.type === "dark"
                ? theme.palette.grey[600]
                : theme.palette.grey[50],
        },
        [theme.breakpoints.up("sm")]: {
            borderBottomColor: "transparent !important",
        },
        backgroundColor: theme.palette.grey[800],
        "& .MuiSvgIcon-root": {
            [theme.breakpoints.only("xs")]: {
                width: 24,
                height: 24,
                fill: theme.palette.type === "dark"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[700],
            },
            fill: theme.palette.grey[100],
        },
        "& .MuiIconButton-root": {
            padding: theme.spacing(1),
        },
    },
    toolbar: {
        minHeight: 48,
    },
    logo: {
        marginRight: theme.spacing(1),
        "& path": {
            fill: theme.palette.common.white,
        },
    },
    smallLogo: {
        width: 24,
        marginLeft: 4,
    },
    title: {
        [theme.breakpoints.only("xs")]: {
            color: theme.palette.type === "dark"
                ? theme.palette.common.white
                : theme.palette.grey[800],
        },
        color: theme.palette.common.white,
    },
    logoTitle: {
        color: theme.palette.common.white,
    },
    right: {
        marginLeft: "auto",
    },
}));
export const AppHeader = forwardRef(({ title, left = null, right = null, ...props }, ref) => {
    const large = useMediaQuery((theme) => theme.breakpoints.up("sm"));
    const classes = useStyles({ large });
    return (React.createElement(Box, { ...props, 
        // @ts-ignore
        ref: ref },
        !large && (React.createElement(StripHeader, null,
            React.createElement(MicroLogo, { className: `${classes.logo} ${classes.smallLogo}` }),
            React.createElement(CoMTitle, { className: classes.logoTitle }, "City of Melbourne"))),
        (large || left || right || title) && (React.createElement(AppBar, { position: "static", className: classes.appbar, elevation: 0 },
            React.createElement(Toolbar, { className: classes.toolbar },
                left,
                large && (React.createElement(React.Fragment, null,
                    React.createElement(MicroLogo, { className: classes.logo }),
                    React.createElement(CoMTitle, null, "City of Melbourne"))),
                React.createElement(CoMTitle, { className: classes.title }, title),
                right && React.createElement("div", { className: classes.right }, right))))));
});
const StripHeader = ({ children }) => {
    return (React.createElement(Box, { display: "flex", alignItems: "center", height: 36, pl: 1, bgcolor: "grey.800" }, children));
};
const CoMTitle = ({ children, ...props }) => {
    return (React.createElement(Box, { component: "span", fontFamily: "CoM", fontSize: { xs: 18, sm: 24 }, position: "relative", top: 1, mr: 2, ...props }, children));
};
// export const AppHeader: FC<MicroHeaderProps> = ({ title, children }) => {
//   return (
//     <Box
//       display="flex"
//       flexDirection={{ xs: "column", sm: "row" }}
//       position="relative"
//       bgcolor="grey.900"
//     >
//       <Box
//         pl={1}
//         display="flex"
//         alignItems="center"
//         height={{ xs: 36, sm: 44 }}
//       >
//         <MicroLogo width={{ xs: 26, sm: 32 }} style={{ fill: "#fff" }} />
//         <Box
//           component="h1"
//           fontFamily="CoM"
//           fontWeight="normal"
//           p={0}
//           display="inline"
//           height={24}
//           lineHeight={1}
//           ml={1.5}
//           position="relative"
//           top={2}
//           color="white"
//         >
//           City of Melbourne
//         </Box>
//       </Box>
//       {title && (
//         <Box
//           bgcolor={{ xs: "grey.500", sm: "transparent" }}
//           color="white"
//           height={{ xs: 36, sm: 44 }}
//           display="flex"
//           alignItems="center"
//           pl={{ xs: 1, sm: 2 }}
//         >
//           <Box fontFamily="CoM" fontSize={24} position="relative" top={2}>
//             {title}
//           </Box>
//         </Box>
//       )}
//       {children && (
//         <Box position="absolute" right={0} top={0}>
//           {children}
//         </Box>
//       )}
//     </Box>
//   )
// }
