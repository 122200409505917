import { LocationEdit } from "./LocationEdit"
import { LocationCreate } from "./LocationCreate"
import { LocationList } from "./LocationsList"
import { LocationCity as LocationCityIcon } from "@material-ui/icons"

const exports = {
  list: LocationList,
  create: LocationCreate,
  edit: LocationEdit,
  icon: LocationCityIcon,
}

export default exports
