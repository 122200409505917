import React from "react"
import {
  List,
  Datagrid,
  TextField,
  UrlField,
  EditButton,
  SingleFieldList,
  ArrayField,
  ChipField,
} from "react-admin"
import { S3ImageField, AmplifyPagination } from "ra-aws-amplify"

import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  code: {
    fontFamily: "'JetBrains Mono', 'Courier New', sans-serif",
    fontSize: 13,
  },
})

export const AppList: React.FC = (props) => {
  const classes = useStyles()
  return (
    <List {...props} pagination={<AmplifyPagination />}>
      <Datagrid>
        <S3ImageField
          // @ts-ignore
          label="Cover Image"
          source="coverImage"
          imgProps={{ width: 64, height: 64 }}
        />
        <TextField source="title" />
        <TextField
          source="npmPackageName"
          label="npm package name"
          className={classes.code}
        />
        <TextField
          source="appId"
          label="App ID"
          emptyText="No App ID for this application"
        />
        <ArrayField source="supportedDisplays">
          <SingleFieldList linkType={false}>
            <ChipField source="type" size="small" />
          </SingleFieldList>
        </ArrayField>
        <TextField source="description" />
        <UrlField source="previewUrl" />
        <EditButton />
      </Datagrid>
    </List>
  )
}
