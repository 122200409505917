import React from "react"
import {
  List,
  Datagrid,
  TextField,
  // DateField,
  RefreshButton,
  TopToolbar,
} from "react-admin"
import { useDispatch } from "react-redux"
import { refreshView } from "ra-core"
import { API } from "aws-amplify"
import { AmplifyPagination } from "ra-aws-amplify"

export const TokenList: React.FC = (props) => {
  const dispatch = useDispatch()

  const handleClick = async (e: any) => {
    e.preventDefault()
    const token = await API.get("api466165c9", "/generatetoken", {})
    console.log(token)
    dispatch(refreshView())
  }

  return (
    <List
      {...props}
      pagination={<AmplifyPagination />}
      actions={
        <TopToolbar>
          <RefreshButton onClick={handleClick} label="generate" />
        </TopToolbar>
      }
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="token" />
        {/* <QRField source="" /> */}
      </Datagrid>
    </List>
  )
}
