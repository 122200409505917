import "./fonts/fonts.css";
export const fontFamily = [
    "Gotham Pro",
    "Monserrat",
    "Arial",
    "Roboto",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(",");
export const comFontFamily = ["CoM", "Impact", "Arial", "sans-serif"];
/**
 * Primary colour palette is dark background.
 * Secondary is white background.
 */
export const colors = {
    pop: {
        blueLight: "#00AECB",
        blueMid: "#277BB4",
        green: "#008850",
        magenta: "#E50E56",
        orange: "#F07B05",
        purple: "#645FAA",
        yellow: "#FFC726",
    },
    base: {
        blueLight: "#CBE3EF",
        blueMid: "#ADCCEB",
        green: "#E1ECC6",
        magenta: "#FAB2CA",
        orange: "#F8ECCD",
        purple: "#C0BDDE",
        yellow: "#FFF3CC",
    },
    light: {
        yellow: "#FFE394",
        blueLight: "#3DE3FF",
        orange: "#FCB064",
        purple: "#ABA8D1",
        green: "#00EB8A",
        magenta: "#FCCFDE",
        blueMid: "#CDE4F4",
    },
};
export const base = {
    palette: {
        grey: {
            50: "#f8f8f8",
            100: "#f2f3f4",
            200: "#d4d6db",
            300: "#888B93",
            400: "#5D616C",
            500: "#3c404b",
            600: "#30323B",
            700: "#23242b",
            800: "#16161A",
            900: "#080809",
        },
        pop: {
            blueLight: colors.pop.blueLight,
            blueMid: colors.pop.blueMid,
            green: colors.pop.green,
            magenta: colors.pop.magenta,
            orange: colors.pop.orange,
            purple: colors.pop.purple,
            yellow: colors.pop.yellow,
        },
        base: {
            blueLight: colors.base.blueLight,
            blueMid: colors.base.blueMid,
            green: colors.base.green,
            magenta: colors.base.magenta,
            orange: colors.base.orange,
            purple: colors.base.purple,
            yellow: colors.base.yellow,
        },
        common: {
            black: "#000",
            white: "#fff",
        },
        primary: { main: colors.pop.magenta },
        secondary: { main: colors.pop.blueMid },
        error: { main: colors.pop.orange },
        success: { main: colors.pop.green },
    },
    shape: {
        borderRadius: 5,
    },
    typography: {
        htmlFontSize: 12,
        // Use the system font instead of the default Roboto font.
        fontFamily,
        h1: {
            fontSize: 32.44,
            lineHeight: 1.5,
            fontWeight: "normal",
        },
        h2: {
            fontSize: 20.25,
            fontWeight: "normal",
        },
        h3: {
            fontSize: 16,
            fontWeight: "normal",
        },
        h4: {
            fontSize: 14.22,
        },
        h5: {
            fontSize: 12.64,
            textTransform: "uppercase",
        },
        body1: {
            fontSize: 16,
            lineHeight: "24px",
            maxWidth: 600,
        },
        body2: {
            fontSize: 14.22,
            lineHeight: 1.5,
        },
        button: {
            fontSize: 14.22,
        },
    },
    overrides: {
        MuiAppBar: {
            root: {
                "&.MuiPaper-elevation0": {
                    borderColor: "transparent",
                    borderBottom: "1px solid",
                },
                "& .MuiTypography-root": {
                    fontFamily: "CoM",
                    fontSize: 25.63,
                    position: "relative",
                    top: 2,
                },
            },
        },
        MuiAvatar: {},
        MuiBottomNavigation: {
            root: {
                borderTop: "1px solid",
            },
        },
        MuiBottomNavigationAction: {
            root: {
                "& .MuiSvgIcon-root": {
                    width: 24,
                    height: 24,
                },
            },
            label: {
                fontSize: 11.24,
                lineHeight: "16px",
                marginTop: 0.5,
                "&.Mui-selected": {
                    fontSize: 11.24,
                },
            },
        },
        MuiButton: {
            root: {
                height: 46,
                textTransform: "none",
                fontWeight: "bold",
                // paddingTop: 8,
                // paddingBottom: 8,
                paddingLeft: 30,
                paddingRight: 30,
            },
            label: {
                position: "relative",
                top: 1,
            },
            contained: {
                boxShadow: "none",
                "&:hover": {
                    boxShadow: "none",
                },
            },
            outlined: {
                paddingLeft: 30,
                paddingRight: 30,
            },
        },
        MuiCard: {
            root: {
                boxShadow: "none",
                border: "1px solid",
                borderRadius: 0,
            },
        },
        MuiCardHeader: {
            title: {
                fontSize: 18,
                textTransform: "none",
            },
        },
        MuiChip: {
            root: {
                // borderRadius: 5,
                border: "1px solid",
                "& .MuiAvatar-root": {
                    fontWeight: "bold",
                    fontSize: 11.24,
                },
            },
            icon: {
                width: 24,
                height: 24,
            },
            label: {
                fontSize: 14.22,
            },
            avatar: {},
        },
        MuiFab: {
            root: {
                fontWeight: "bold",
                height: 46,
                width: 46,
                boxShadow: "none",
                border: "1px solid",
                "& .MuiSvgIcon-root": {
                    position: "relative",
                    top: -1,
                    width: 24,
                    height: 24,
                },
            },
            label: {
                textTransform: "none",
                position: "relative",
                top: 1,
            },
            extended: {
                paddingLeft: 30,
                paddingRight: 30,
                "& .MuiSvgIcon-root": {
                    marginRight: 8,
                },
            },
        },
        MuiFilledInput: {
            root: {
                borderRadius: 5,
            },
        },
        MuiPaper: {
            root: {
                border: "1px solid transparent",
            },
            rounded: {
                borderRadius: 0,
            },
        },
        MuiSnackbar: {},
        MuiSnackbarContent: {
            root: {
                boxShadow: "none",
                // border: "none",
            },
        },
    },
};
