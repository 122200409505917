/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      title
      viewport {
        zoom
        latitude
        longitude
        bearing
      }
      rooms {
        items {
          id
          locationId
          title
          slug
          latlng {
            latitude
            longitude
          }
          nowPlaying {
            id
            appId
            title
            npmPackageName
            previewUrl
            description
            coverImage {
              key
              identityId
              level
              type
            }
            supportedDisplays {
              type
            }
            createdAt
            updatedAt
          }
          displays {
            type
          }
          devices {
            items {
              id
              name
              userAgent
              roomId
              display {
                type
              }
              currentAppScreen
              lastKnownLocation {
                latitude
                longitude
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      title
      viewport {
        zoom
        latitude
        longitude
        bearing
      }
      rooms {
        items {
          id
          locationId
          title
          slug
          latlng {
            latitude
            longitude
          }
          nowPlaying {
            id
            appId
            title
            npmPackageName
            previewUrl
            description
            coverImage {
              key
              identityId
              level
              type
            }
            supportedDisplays {
              type
            }
            createdAt
            updatedAt
          }
          displays {
            type
          }
          devices {
            items {
              id
              name
              userAgent
              roomId
              display {
                type
              }
              currentAppScreen
              lastKnownLocation {
                latitude
                longitude
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      title
      viewport {
        zoom
        latitude
        longitude
        bearing
      }
      rooms {
        items {
          id
          locationId
          title
          slug
          latlng {
            latitude
            longitude
          }
          nowPlaying {
            id
            appId
            title
            npmPackageName
            previewUrl
            description
            coverImage {
              key
              identityId
              level
              type
            }
            supportedDisplays {
              type
            }
            createdAt
            updatedAt
          }
          displays {
            type
          }
          devices {
            items {
              id
              name
              userAgent
              roomId
              display {
                type
              }
              currentAppScreen
              lastKnownLocation {
                latitude
                longitude
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRoom = /* GraphQL */ `
  mutation CreateRoom(
    $input: CreateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    createRoom(input: $input, condition: $condition) {
      id
      locationId
      title
      slug
      latlng {
        latitude
        longitude
      }
      nowPlaying {
        id
        appId
        title
        npmPackageName
        previewUrl
        description
        coverImage {
          key
          identityId
          level
          type
        }
        supportedDisplays {
          type
        }
        createdAt
        updatedAt
      }
      displays {
        type
      }
      devices {
        items {
          id
          name
          userAgent
          roomId
          display {
            type
          }
          currentAppScreen
          lastKnownLocation {
            latitude
            longitude
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRoom = /* GraphQL */ `
  mutation UpdateRoom(
    $input: UpdateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    updateRoom(input: $input, condition: $condition) {
      id
      locationId
      title
      slug
      latlng {
        latitude
        longitude
      }
      nowPlaying {
        id
        appId
        title
        npmPackageName
        previewUrl
        description
        coverImage {
          key
          identityId
          level
          type
        }
        supportedDisplays {
          type
        }
        createdAt
        updatedAt
      }
      displays {
        type
      }
      devices {
        items {
          id
          name
          userAgent
          roomId
          display {
            type
          }
          currentAppScreen
          lastKnownLocation {
            latitude
            longitude
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRoom = /* GraphQL */ `
  mutation DeleteRoom(
    $input: DeleteRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    deleteRoom(input: $input, condition: $condition) {
      id
      locationId
      title
      slug
      latlng {
        latitude
        longitude
      }
      nowPlaying {
        id
        appId
        title
        npmPackageName
        previewUrl
        description
        coverImage {
          key
          identityId
          level
          type
        }
        supportedDisplays {
          type
        }
        createdAt
        updatedAt
      }
      displays {
        type
      }
      devices {
        items {
          id
          name
          userAgent
          roomId
          display {
            type
          }
          currentAppScreen
          lastKnownLocation {
            latitude
            longitude
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createApp = /* GraphQL */ `
  mutation CreateApp(
    $input: CreateAppInput!
    $condition: ModelAppConditionInput
  ) {
    createApp(input: $input, condition: $condition) {
      id
      appId
      title
      npmPackageName
      previewUrl
      description
      coverImage {
        key
        identityId
        level
        type
      }
      supportedDisplays {
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateApp = /* GraphQL */ `
  mutation UpdateApp(
    $input: UpdateAppInput!
    $condition: ModelAppConditionInput
  ) {
    updateApp(input: $input, condition: $condition) {
      id
      appId
      title
      npmPackageName
      previewUrl
      description
      coverImage {
        key
        identityId
        level
        type
      }
      supportedDisplays {
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteApp = /* GraphQL */ `
  mutation DeleteApp(
    $input: DeleteAppInput!
    $condition: ModelAppConditionInput
  ) {
    deleteApp(input: $input, condition: $condition) {
      id
      appId
      title
      npmPackageName
      previewUrl
      description
      coverImage {
        key
        identityId
        level
        type
      }
      supportedDisplays {
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProjectionCalibration = /* GraphQL */ `
  mutation CreateProjectionCalibration(
    $input: CreateProjectionCalibrationInput!
    $condition: ModelProjectionCalibrationConditionInput
  ) {
    createProjectionCalibration(input: $input, condition: $condition) {
      id
      title
      geojson {
        key
        identityId
        level
        type
      }
      mapStyle
      createdAt
      updatedAt
    }
  }
`;
export const updateProjectionCalibration = /* GraphQL */ `
  mutation UpdateProjectionCalibration(
    $input: UpdateProjectionCalibrationInput!
    $condition: ModelProjectionCalibrationConditionInput
  ) {
    updateProjectionCalibration(input: $input, condition: $condition) {
      id
      title
      geojson {
        key
        identityId
        level
        type
      }
      mapStyle
      createdAt
      updatedAt
    }
  }
`;
export const deleteProjectionCalibration = /* GraphQL */ `
  mutation DeleteProjectionCalibration(
    $input: DeleteProjectionCalibrationInput!
    $condition: ModelProjectionCalibrationConditionInput
  ) {
    deleteProjectionCalibration(input: $input, condition: $condition) {
      id
      title
      geojson {
        key
        identityId
        level
        type
      }
      mapStyle
      createdAt
      updatedAt
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice(
    $input: CreateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    createDevice(input: $input, condition: $condition) {
      id
      name
      userAgent
      roomId
      display {
        type
      }
      currentAppScreen
      lastKnownLocation {
        latitude
        longitude
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice(
    $input: UpdateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    updateDevice(input: $input, condition: $condition) {
      id
      name
      userAgent
      roomId
      display {
        type
      }
      currentAppScreen
      lastKnownLocation {
        latitude
        longitude
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice(
    $input: DeleteDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    deleteDevice(input: $input, condition: $condition) {
      id
      name
      userAgent
      roomId
      display {
        type
      }
      currentAppScreen
      lastKnownLocation {
        latitude
        longitude
      }
      createdAt
      updatedAt
    }
  }
`;
export const createControl = /* GraphQL */ `
  mutation CreateControl(
    $input: CreateControlInput!
    $condition: ModelControlConditionInput
  ) {
    createControl(input: $input, condition: $condition) {
      id
      appId
      roomCapacity
      emitLimit
      emitLimitResetTime
      entryTimeLimit
      createdAt
      updatedAt
    }
  }
`;
export const updateControl = /* GraphQL */ `
  mutation UpdateControl(
    $input: UpdateControlInput!
    $condition: ModelControlConditionInput
  ) {
    updateControl(input: $input, condition: $condition) {
      id
      appId
      roomCapacity
      emitLimit
      emitLimitResetTime
      entryTimeLimit
      createdAt
      updatedAt
    }
  }
`;
export const deleteControl = /* GraphQL */ `
  mutation DeleteControl(
    $input: DeleteControlInput!
    $condition: ModelControlConditionInput
  ) {
    deleteControl(input: $input, condition: $condition) {
      id
      appId
      roomCapacity
      emitLimit
      emitLimitResetTime
      entryTimeLimit
      createdAt
      updatedAt
    }
  }
`;
export const createToken = /* GraphQL */ `
  mutation CreateToken(
    $input: CreateTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    createToken(input: $input, condition: $condition) {
      id
      token
      createdAt
      updatedAt
    }
  }
`;
export const updateToken = /* GraphQL */ `
  mutation UpdateToken(
    $input: UpdateTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    updateToken(input: $input, condition: $condition) {
      id
      token
      createdAt
      updatedAt
    }
  }
`;
export const deleteToken = /* GraphQL */ `
  mutation DeleteToken(
    $input: DeleteTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    deleteToken(input: $input, condition: $condition) {
      id
      token
      createdAt
      updatedAt
    }
  }
`;
