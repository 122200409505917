/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      title
      viewport {
        zoom
        latitude
        longitude
        bearing
      }
      rooms {
        items {
          id
          locationId
          title
          slug
          latlng {
            latitude
            longitude
          }
          nowPlaying {
            id
            appId
            title
            npmPackageName
            previewUrl
            description
            coverImage {
              key
              identityId
              level
              type
            }
            supportedDisplays {
              type
            }
            createdAt
            updatedAt
          }
          displays {
            type
          }
          devices {
            items {
              id
              name
              userAgent
              roomId
              display {
                type
              }
              currentAppScreen
              lastKnownLocation {
                latitude
                longitude
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        viewport {
          zoom
          latitude
          longitude
          bearing
        }
        rooms {
          items {
            id
            locationId
            title
            slug
            latlng {
              latitude
              longitude
            }
            nowPlaying {
              id
              appId
              title
              npmPackageName
              previewUrl
              description
              coverImage {
                key
                identityId
                level
                type
              }
              supportedDisplays {
                type
              }
              createdAt
              updatedAt
            }
            displays {
              type
            }
            devices {
              items {
                id
                name
                userAgent
                roomId
                display {
                  type
                }
                currentAppScreen
                lastKnownLocation {
                  latitude
                  longitude
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRoom = /* GraphQL */ `
  query GetRoom($id: ID!) {
    getRoom(id: $id) {
      id
      locationId
      title
      slug
      latlng {
        latitude
        longitude
      }
      nowPlaying {
        id
        appId
        title
        npmPackageName
        previewUrl
        description
        coverImage {
          key
          identityId
          level
          type
        }
        supportedDisplays {
          type
        }
        createdAt
        updatedAt
      }
      displays {
        type
      }
      devices {
        items {
          id
          name
          userAgent
          roomId
          display {
            type
          }
          currentAppScreen
          lastKnownLocation {
            latitude
            longitude
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRooms = /* GraphQL */ `
  query ListRooms(
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        locationId
        title
        slug
        latlng {
          latitude
          longitude
        }
        nowPlaying {
          id
          appId
          title
          npmPackageName
          previewUrl
          description
          coverImage {
            key
            identityId
            level
            type
          }
          supportedDisplays {
            type
          }
          createdAt
          updatedAt
        }
        displays {
          type
        }
        devices {
          items {
            id
            name
            userAgent
            roomId
            display {
              type
            }
            currentAppScreen
            lastKnownLocation {
              latitude
              longitude
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getApp = /* GraphQL */ `
  query GetApp($id: ID!) {
    getApp(id: $id) {
      id
      appId
      title
      npmPackageName
      previewUrl
      description
      coverImage {
        key
        identityId
        level
        type
      }
      supportedDisplays {
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const listApps = /* GraphQL */ `
  query ListApps(
    $filter: ModelAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        appId
        title
        npmPackageName
        previewUrl
        description
        coverImage {
          key
          identityId
          level
          type
        }
        supportedDisplays {
          type
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProjectionCalibration = /* GraphQL */ `
  query GetProjectionCalibration($id: ID!) {
    getProjectionCalibration(id: $id) {
      id
      title
      geojson {
        key
        identityId
        level
        type
      }
      mapStyle
      createdAt
      updatedAt
    }
  }
`;
export const listProjectionCalibrations = /* GraphQL */ `
  query ListProjectionCalibrations(
    $filter: ModelProjectionCalibrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectionCalibrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        geojson {
          key
          identityId
          level
          type
        }
        mapStyle
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      name
      userAgent
      roomId
      display {
        type
      }
      currentAppScreen
      lastKnownLocation {
        latitude
        longitude
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        userAgent
        roomId
        display {
          type
        }
        currentAppScreen
        lastKnownLocation {
          latitude
          longitude
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const roomsByLocation = /* GraphQL */ `
  query RoomsByLocation(
    $locationId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roomsByLocation(
      locationId: $locationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationId
        title
        slug
        latlng {
          latitude
          longitude
        }
        nowPlaying {
          id
          appId
          title
          npmPackageName
          previewUrl
          description
          coverImage {
            key
            identityId
            level
            type
          }
          supportedDisplays {
            type
          }
          createdAt
          updatedAt
        }
        displays {
          type
        }
        devices {
          items {
            id
            name
            userAgent
            roomId
            display {
              type
            }
            currentAppScreen
            lastKnownLocation {
              latitude
              longitude
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const devicesByRoom = /* GraphQL */ `
  query DevicesByRoom(
    $roomId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    devicesByRoom(
      roomId: $roomId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        userAgent
        roomId
        display {
          type
        }
        currentAppScreen
        lastKnownLocation {
          latitude
          longitude
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getToken = /* GraphQL */ `
  query GetToken($id: ID!) {
    getToken(id: $id) {
      id
      token
      createdAt
      updatedAt
    }
  }
`;
export const listTokens = /* GraphQL */ `
  query ListTokens(
    $filter: ModelTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        token
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getControl = /* GraphQL */ `
  query GetControl($id: ID!) {
    getControl(id: $id) {
      id
      appId
      roomCapacity
      emitLimit
      emitLimitResetTime
      entryTimeLimit
      createdAt
      updatedAt
    }
  }
`;
export const listControls = /* GraphQL */ `
  query ListControls(
    $filter: ModelControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listControls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        appId
        roomCapacity
        emitLimit
        emitLimitResetTime
        entryTimeLimit
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const controlByAppId = /* GraphQL */ `
  query ControlByAppId(
    $appId: String
    $sortDirection: ModelSortDirection
    $filter: ModelControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    controlByAppId(
      appId: $appId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        appId
        roomCapacity
        emitLimit
        emitLimitResetTime
        entryTimeLimit
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
