import React from "react"
import { Box, Typography } from "@material-ui/core"
import { Title } from "react-admin"

export const UserList: React.FC = () => (
  <>
    <Title title="User Management" />
    <Box
      flexGrow={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h3">User management coming soon</Typography>
    </Box>
  </>
)
