import React from "react"
import { Edit, SimpleForm, TextInput } from "react-admin"
import { MapInput } from "../common"

export const LocationEdit: React.FC = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="title" />
        <MapInput
          source="viewport"
          point={false}
          mapProps={{ width: 680, height: 515 }}
          type="location"
        />
      </SimpleForm>
    </Edit>
  )
}
