import React from "react"
import { List, Datagrid, TextField, EditButton, ChipField } from "react-admin"
import { AmplifyPagination } from "ra-aws-amplify"
import { FileCopy as FileIcon } from "@material-ui/icons"

import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  code: {
    fontFamily: "'JetBrains Mono', 'Courier New', sans-serif",
    fontSize: 13,
  },
})

export const ProjectionCalibrationList: React.FC = props => {
  const classes = useStyles()
  return (
    <List
      {...props}
      title="Projection Calibrations"
      pagination={<AmplifyPagination />}
    >
      <Datagrid>
        <TextField source="title" />
        <ChipField
          label="Geojson file"
          icon={<FileIcon />}
          source="geojson.key"
        />
        <TextField source="mapStyle" className={classes.code} />
        <EditButton />
      </Datagrid>
    </List>
  )
}
