import React, { FC } from "react"
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin"

const displayTypeChoices = [
  { id: "Projection", name: "Projection" },
  { id: "Screen", name: "Screen" },
  { id: "Controller", name: "Controller" },
]

const EditTitle = ({ record }: any) => {
  return <span>{`Editing - ${record.name}`}</span>
}

export const DeviceEdit: FC = (props) => {
  return (
    <Edit title={<EditTitle />} {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <ReferenceInput label="Room" source="roomId" reference="Room">
          <SelectInput
            allowEmpty
            emptyValue={null}
            optionText="title"
            optionValue="id"
          />
        </ReferenceInput>
        <SelectInput
          source="display.type"
          label="Display type"
          choices={displayTypeChoices}
        />
      </SimpleForm>
    </Edit>
  )
}
