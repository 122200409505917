import React from "react"
import { Box, Typography } from "@material-ui/core"
import { GetApp as GetAppIcon } from "@material-ui/icons"

export const DropPlaceholder: React.FC = ({ children }) => {
  return (
    <Box textAlign="center" p={2}>
      <GetAppIcon color="secondary" style={{ fontSize: 128 }} />
      <Typography color="secondary" variant="h6">
        {children}
      </Typography>
    </Box>
  )
}
