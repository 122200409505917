import { Devices as DevicesIcon } from "@material-ui/icons"
import { DeviceList } from "./DeviceList"
import { DeviceEdit } from "./DeviceEdit"

/**
 * WARNING: Do no allow the ability to create devices in this admin app.
 *
 * You need to make sure that you enroll the device using the platform-host app
 * so that the device knows of it's record in the DB.
 */
const exports = {
  edit: DeviceEdit,
  list: DeviceList,
  icon: DevicesIcon,
}

export default exports
