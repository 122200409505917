import { Apps as AppsIcon } from "@material-ui/icons"
import { AppList } from "./AppList"
import { AppCreate } from "./AppCreate"
import { AppEdit } from "./AppEdit"

const exports = {
  list: AppList,
  create: AppCreate,
  edit: AppEdit,
  icon: AppsIcon,
}

export default exports
