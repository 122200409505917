import { ProjectionCalibrationList } from "./ProjectionCalibrationList"
import { Tune as TuneIcon } from "@material-ui/icons"
import { ProjectionCalibrationCreate } from "./ProjectionCalibrationCreate"
import { ProjectionCalibrationEdit } from "./ProjectionCalibrationEdit"

const exports = {
  list: ProjectionCalibrationList,
  create: ProjectionCalibrationCreate,
  edit: ProjectionCalibrationEdit,
  icon: TuneIcon,
}

export default exports
