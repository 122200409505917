import { PanTool as PanToolIcon } from "@material-ui/icons"
import { ControlList } from "./ControlList"
import { ControlEdit } from "./ControlEdit"
import { ControlCreate } from "./ControlCreate"

const exports = {
  create: ControlCreate,
  list: ControlList,
  edit: ControlEdit,
  icon: PanToolIcon,
}

export default exports
