import React from "react"
import { Create, SimpleForm, TextInput, regex, required } from "react-admin"

export const ControlCreate: React.FC = (props) => {
  return (
    <Create {...props}>
      <SimpleForm redirects="list">
        <TextInput
          source="appId"
          label="App ID"
          helperText="The id to identify different entry points in a single app (e.g. aboriginal-melbourne and state-library-images)"
          validate={[
            regex(
              /[a-z]+(-[a-z]+)*/i,
              "App ID must consist of only lowercase letters and hyphens (-)"
            ),
            required(),
          ]}
        />
        <TextInput
          source="roomCapacity"
          helperText="The number of participants allowed into the platform at a given time"
          parse={(input: string) => {
            return Number(input)
          }}
          validate={[required()]}
        />
        <TextInput
          source="emitLimit"
          helperText="The number of interactions a participant can queue or project items"
          parse={(input: string) => {
            return Number(input)
          }}
          validate={[required()]}
        />
        <TextInput
          source="emitLimitResetTime"
          label="Emit limit reset time (seconds)"
          helperText="The number of seconds when a participant emit limit is reset"
          parse={(input: string) => {
            return Number(input)
          }}
          validate={[required()]}
        />
        <TextInput
          source="entryTimeLimit"
          label="Entry time limit (minutes)"
          helperText="The number of minutes a participant can stay connected to the platform"
          parse={(input: string) => {
            return Number(input)
          }}
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  )
}
