import React from "react"
import { Edit, SimpleForm, TextInput } from "react-admin"
import { S3FileInput } from "ra-aws-amplify"
import { makeStyles } from "@material-ui/core"
import { DropPlaceholder } from "../common"
const useStyles = makeStyles({
  codeInput: {
    "& input": {
      fontFamily: "'JetBrains Mono', 'Courier New', sans-serif",
    },
  },
})

export const ProjectionCalibrationEdit: React.FC = (props) => {
  const classes = useStyles()
  return (
    <Edit {...props}
      //@ts-ignore
      redirect="list"
      title="Edit projection calibration"
    >
      <SimpleForm>
        <TextInput source="title" />
        <S3FileInput
          source="geojson"
          level="public"
          dropzoneOptions={{}}
          // @ts-ignore
          placeholder={
            <DropPlaceholder>
              Drop GeoJSON file here (or click to select)
            </DropPlaceholder>
          }
        />
        <TextInput source="mapStyle" className={classes.codeInput} />
      </SimpleForm>
    </Edit>
  )
}
