import React from "react"
import { Chip } from "@material-ui/core"
import { Place as PlaceIcon } from "@material-ui/icons"

export const LatLngField: React.FC<{
  record?: Record<string, any>
  source: string
  trim?: boolean
  label?: string
}> = ({ record, source, trim = true }) => {
  const value = record && record[source]
  const latitude =
    trim && value
      ? (value.latitude || value.lat).toFixed(4)
      : value.latitude || value.lat
  const longitude =
    trim && value
      ? (value.longitude || value.lng || value.lon).toFixed(4)
      : value.longitude || value.lng || value.lon
  const chipLabel = value && `${latitude}, ${longitude}`
  return chipLabel ? <Chip icon={<PlaceIcon />} label={chipLabel} /> : null
}
