import React from "react"
import { Create, SimpleForm, TextInput, required, regex } from "react-admin"
import { S3FileInput } from "ra-aws-amplify"
import { makeStyles } from "@material-ui/core"
import { DropPlaceholder } from "../common"
const useStyles = makeStyles({
  codeInput: {
    "& input": {
      fontFamily: "'JetBrains Mono', 'Courier New', sans-serif",
    },
  },
})

export const ProjectionCalibrationCreate: React.FC = (props) => {
  const classes = useStyles()
  return (
    <Create {...props}
      //@ts-ignore
      redirect="list"
      title="Create projection calibration"
    >
      <SimpleForm>
        <TextInput source="title" validate={required()} />
        <S3FileInput
          source="geojson"
          level="public"
          // @ts-ignore
          validate={required()}
          dropzoneOptions={{}}
          // @ts-ignore
          placeholder={
            <DropPlaceholder>
              Drop GeoJSON file here (or click to select)
            </DropPlaceholder>
          }
        />
        <TextInput
          source="mapStyle"
          helperText="Override the projection map style"
          className={classes.codeInput}
          validate={regex(
            /^mapbox:\/\//i,
            "mapStyle must begin with mapbox://"
          )}
        />
      </SimpleForm>
    </Create>
  )
}
