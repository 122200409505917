import React from "react"
import { AmplifyPagination } from "ra-aws-amplify"
import { ChipField } from "react-admin"
import { SingleFieldList } from "react-admin"
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  ArrayField,
} from "react-admin"
import { LatLngField } from "../common/LatLngField"

export const RoomList: React.FC = (props) => {
  return (
    <List {...props} pagination={<AmplifyPagination />}>
      <Datagrid>
        <TextField source="title" />
        <LatLngField source="latlng" label="Position" />
        <ReferenceField reference="Location" source="locationId">
          <TextField source="title" />
        </ReferenceField>
        <ArrayField source="displays">
          <SingleFieldList linkType={false}>
            <ChipField source="type" />
          </SingleFieldList>
        </ArrayField>
        <ReferenceField
          reference="App"
          source="roomNowPlayingId"
          label="App now playing"
        >
          <TextField source="title" />
        </ReferenceField>
        <EditButton />
      </Datagrid>
    </List>
  )
}
