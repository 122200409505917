import React from "react"
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin"
import { MapInput } from "../common"

export const LocationCreate: React.FC = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="title" />
        <MapInput source="viewport" point={false} type="location" />
        <ReferenceArrayField
          source="rooms"
          reference="Room"
          // @ts-ignore
          target="roomsByLocation"
        >
          <SingleFieldList>
            <ChipField source="title" />
          </SingleFieldList>
        </ReferenceArrayField>
      </SimpleForm>
    </Create>
  )
}
