import React, { useState, useRef, useEffect } from "react"
import { useInput, Labeled, TextInput, useDataProvider } from "react-admin"
import { InteractiveMap, Marker } from "react-map-gl"
import { makeStyles, Theme, useTheme } from "@material-ui/core"
import { Place as PlaceIcon } from "@material-ui/icons"
import { InteractiveMapProps } from "react-map-gl/src/components/interactive-map"
import { LngLat } from "@citydna/maps"
import { Source, Layer } from "react-map-gl"
import { FeatureCollection, Point } from "geojson"
import { featureCollection, point as pointFeature } from "@turf/helpers"
import "./MapInput.css"

interface MapInputProps {
  source: string
  point?: boolean
  string?: boolean
  existingLocation?: any | undefined
  record?: Record<string, any>
  mapProps?: InteractiveMapProps
  currentRoomId?: string
  type: "room" | "location"
}

type MapboxEventCallback = {
  lngLat: LngLat
}

const stringify = (obj: Record<string, string>, stringify: boolean) =>
  stringify ? JSON.stringify(obj) : obj

const useStyles = makeStyles((theme: Theme) => ({
  marker: {
    fontSize: 32,
    color: theme.palette.primary.main,
  },
  json: {
    "& textarea": {
      fontFamily: "'JetBrains Mono', 'Courier New', sans-serif",
    },
  },
}))

export const MapInput: React.FC<MapInputProps> = ({
  source,
  point = true,
  string = false,
  existingLocation,
  record,
  mapProps = {},
  currentRoomId,
  type,
}: any) => {
  const { input } = useInput({ source })
  const dataProvider = useDataProvider()
  const [existingRoomFeatures, setExistingRoomFeatures] = useState<
    FeatureCollection<Point>
  >()
  const theme = useTheme()
  const [viewport, setViewport] = useState(() => {
    const value = record[source]
    return typeof value !== "undefined" &&
      "latitude" in value &&
      "longitude" in value
      ? {
          zoom: 13,
          ...value,
        }
      : {
          latitude: -37.8136,
          longitude: 144.9631,
          zoom: 13,
        }
  })

  /**
   * set viewport to Location's once a location is chosen
   * in the Room Create form
   */
  useEffect(() => {
    if (existingLocation) {
      const vp = existingLocation.viewport
      setViewport({ ...vp, zoom: vp.zoom - 1 })
    }
  }, [existingLocation])

  const [value, setValue] = useState(record[source])

  const mounted = useRef(false)
  const handleInteraction = ({
    inTransition,
    isDragging,
    isPanning,
    isRotating,
    isZooming,
  }: any) => {
    if (
      !point &&
      !inTransition &&
      !isDragging &&
      !isPanning &&
      !isRotating &&
      !isZooming &&
      mounted.current
    ) {
      const { latitude, longitude, zoom, bearing } = viewport
      const value = stringify({ latitude, longitude, zoom, bearing }, string)
      input.onChange(value)
      setValue(value)
    } else {
      mounted.current = true
    }
  }

  const [marker, setMarker] = useState<
    undefined | { longitude: number; latitude: number }
  >(point ? record[source] : undefined)

  /**
   * Set the marker value in all appropriate places
   */
  const setMarkerValue = ({
    longitude,
    latitude,
  }: {
    longitude: number
    latitude: number
  }) => {
    /** On the map */
    setMarker({ longitude, latitude })

    // converting to string was giving issues in the LatLngField component
    // not sure if it is needed or not yet?

    // const value = stringify(
    //   { longitude: longitude.toString(), latitude: latitude.toString() },
    //   string
    // )
    const value = { longitude, latitude }
    input.onChange(value)
    setValue(value)
  }

  /**
   * Handle click on the map to position the marker
   */
  const handleClick = ({
    lngLat: [longitude, latitude],
  }: MapboxEventCallback) => {
    if (!point) return
    setMarkerValue({ longitude, latitude })
  }

  /**
   * User can drag the marker too
   */
  const handleMarkerDragEnd = ({
    lngLat: [longitude, latitude],
  }: MapboxEventCallback) => setMarkerValue({ longitude, latitude })

  /**
   * Set points on the map where the existing rooms are minus
   * the room that is currently being edited
   */
  useEffect(() => {
    let mounted = true
    existingLocation?.rooms?.items &&
      dataProvider
        .getMany("Room", {
          ids: existingLocation.rooms.items.map((item: any) => item.id),
        })
        .then((res: any) => {
          let rooms: any
          if (currentRoomId) {
            rooms = res?.data.filter((room: any) => room.id !== currentRoomId)
          } else {
            rooms = res.data
          }
          if (mounted) {
            setExistingRoomFeatures(
              featureCollection(
                rooms?.map((room: any) =>
                  pointFeature([room.latlng.longitude, room.latlng.latitude], {
                    name: room.title,
                  })
                )
              )
            )
          }
        })
        return () => {
          mounted = false
        }
  }, [currentRoomId, dataProvider, existingLocation])

  const classes = useStyles()

  return (
    <Labeled source={source} label={`Set ${type} location`}>
      <>
        <InteractiveMap
          {...viewport}
          width={800}
          height={300}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN}
          onViewportChange={(viewport: any) => setViewport(viewport)}
          onInteractionStateChange={handleInteraction}
          onClick={handleClick}
          {...mapProps}
        >
          {existingRoomFeatures && (
            <Source data={existingRoomFeatures} type="geojson">
              <Layer
                type="circle"
                paint={{
                  "circle-radius": 10,
                  "circle-color": theme.palette.secondary.main,
                  "circle-stroke-width": 2,
                  "circle-stroke-color": theme.palette.common.white,
                }}
              />
            </Source>
          )}
          {marker && (
            <Marker
              offsetTop={-32}
              offsetLeft={-16}
              draggable
              onDragEnd={handleMarkerDragEnd}
              {...marker}
            >
              <PlaceIcon className={classes.marker} />
            </Marker>
          )}
        </InteractiveMap>
        {!point && value && (
          <TextInput
            className={classes.json}
            disabled
            source={source}
            multiline
            fullWidth
            rows={6}
            format={(val: object) => JSON.stringify(val, null, 2)}
            parse={(val: string) => JSON.parse(val)}
          />
        )}
      </>
    </Labeled>
  )
}
