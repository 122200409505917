import React from "react";
import { Parser, ProcessNodeDefinitions } from "html-to-react";
const { processDefaultNode } = new ProcessNodeDefinitions(React);
const parser = new Parser();
export const HTMLtoReact = ({ html, config }) => {
    const instructions = [
        {
            shouldProcessNode: (node) => true,
            processNode: processDefaultNode,
        },
    ];
    for (const [key, value] of Object.entries(config).reverse()) {
        instructions.unshift({
            shouldProcessNode: node => node.name === key,
            processNode: value,
        });
    }
    return parser.parseWithInstructions(html, () => true, instructions);
};
