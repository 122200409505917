/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:4d225ba8-347f-4e60-942e-58918d685131",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_f7TUkQmZn",
    "aws_user_pools_web_client_id": "55f6hai2bf8f11te33barp28ts",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://bgv342c4uvcv5gb676utnvtlgi.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-mmlg2jeopfhslctpebncm3ox2y",
    "aws_cloud_logic_custom": [
        {
            "name": "api466165c9",
            "endpoint": "https://1wqu1tytfl.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        }
    ],
    "aws_user_files_s3_bucket": "platform-admin-assets114502-production",
    "aws_user_files_s3_bucket_region": "ap-southeast-2",
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
